<template>
  <div class="flex justify-center">
    <div class="flex flex-col items-center w-full">
      <div class="bg-white dark:text-white dark:bg-gray-900 my-5 rounded-md shadow p-3 mx-12 w-full md:w-2/3 lg:w-1/3 text-center">
        <template v-if="getError">
          <span class="text-3xl font-semibold mb-2">Oops...</span>
          <div class="flex justify-center w-full mt-5">
            <img src="https://media.giphy.com/media/xGdvlOVSWaDvi/giphy.gif">
          </div>
          <p class="pt-5 pb-2">
            {{getError}}
          </p>
        </template>
        <template v-else-if="getBooklet">
          <span class="text-3xl font-semibold mb-2">💥 It's GameDay 💥</span>
          <div class="text-xl font-semibold mt-5">
            {{formatDate(getBooklet.game_datetime)}}
          </div>
          <div class="text-xl font-semibold mt-1 mb-2">
            {{getBooklet.home_team}} vs. {{getBooklet.away_team}}
          </div>
          <button class="primary my-5" @click="download()">Matchheft herunterladen</button>
        </template>
      </div>
      <Footer class="fixed bottom-0"></Footer>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import dateMixin from '@/mixin/dateMixin.js'
import ApiClient from '@/assets/js/ApiClient.js'
import Footer from '@/components/Footer'
import fileDownload from 'js-file-download'

export default {
  components: {
    Footer
  },
  mixins: [dateMixin],
  data () {
    return {
      error: null,
      booklet: null
    }
  },
  computed: {
    ...mapGetters({
      bookletsPublishedAll: 'swissunihockey/bookletsPublishedAll'
    }),
    getError () {
      return this.error
    },
    getBooklet () {
      return this.booklet
    }
  },
  methods: {
    download () {
      ApiClient.get(`/api/su/booklets/${this.getBooklet.id}/download?typ=pdf&qrcode=true`, { responseType: 'blob', headers: { Accept: 'application/pdf' } }).then(res => {
        const filename = `${this.formatDateDownload(this.getBooklet.game_datetime)}_vs_${this.getBooklet.away_team.replace(/ /g, '')}.pdf`
        fileDownload(res.data, filename)
      })
    }
  },
  mounted () {
    this.$store.dispatch('startLoading')
    const query = this.$route.query
    setTimeout(() => {
      this.booklet = this.bookletsPublishedAll.find(b => b.id === parseInt(query.id))
      if (this.booklet !== undefined) {
        ApiClient.post(`/api/su/booklets/${this.getBooklet.id}/view_qrcode`)
        const apiUrl = process.env.VUE_APP_API_URL
        this.$store.dispatch('endLoading')
        window.location.href = `${apiUrl}/storage/su/${this.booklet.club_id}/${this.booklet.su_game_id}.pdf`
      } else {
        this.error = 'Dieses Matchheft konnte nicht geladen werden.'
        this.$store.dispatch('endLoading')
      }
    }, 1500)
  }
}

</script>
